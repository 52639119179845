import MetricsEvent from "@lib/EventsCollector/Event";
import type EventsCollectorInterface from "@lib/EventsCollector/EventsCollectorInterface";
import Polyglot from "node-polyglot";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import I18nLoaderException from "./I18nLoaderException";
import I18nLoaderInterface from "./I18nLoaderInterface";

class GwI18n {
    private appliedLoaders: I18nLoaderInterface[] = [];
    private loaders: I18nLoaderInterface[] = [];
    private polyglot: Polyglot;
    private isReady = new BehaviorSubject(false);
    constructor(
        private readonly logger: LoggerInterface,
        private readonly eventsCollector: EventsCollectorInterface
    ) {
        this.polyglot = new Polyglot({
            interpolation: {
                prefix: "{",
                suffix: "}",
            },
        });
    }
    registerLoader(loader: I18nLoaderInterface) {
        this.loaders.push(loader);
    }
    async load() {
        for (const loader of this.loaders) {
            if (this.appliedLoaders.includes(loader)) continue;
            try {
                const records = await loader.load();
                this.logger.debug("GwI18n.load loader loaded translations", {
                    loader,
                    records,
                });
                this.polyglot.extend(records);
                this.appliedLoaders.push(loader);
            } catch (error: unknown) {
                if (error instanceof I18nLoaderException) {
                    this.logger.error(
                        `GwI18n.load ${error.message}`,
                        error.context
                    );
                    this.eventsCollector.pushEvent(
                        new MetricsEvent("i18n.error.LoaderError", new Date(), {
                            errorMessage: error.message,
                        })
                    );
                }
            }
        }
        this.isReady.next(true);
    }
    selectT(
        translationKey: string,
        params?: Record<string, string | number>
    ): Observable<string> {
        return this.isReady.pipe(
            map((isReady) => {
                if (!this.polyglot.has(translationKey)) {
                    this.eventsCollector.pushEvent(
                        new MetricsEvent(
                            "i18n.error.TranslationNotFound",
                            new Date(),
                            {
                                translationKey,
                            }
                        )
                    );
                }
                if (isReady) return this.polyglot.t(translationKey, params);
                return translationKey;
            })
        );
    }
}

export default GwI18n;
