// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WISHLIST (prefix: 'wl') */
:root {
    --gw-wl-main-color: #9a5cb3;
    --gw-wl-icon-color: #fff;
    --gw-wl-drawer-btn-border-radius: 22px;
    --gw-wl-drawer-btn-bg: #fff;
    --gw-wl-drawer-btn-box-shadow: rgb(218, 218, 218) 0px 3px 14px;
    --gw-wl-drawer-btn-text-color: var(--gw-dark-text-color);
    --gw-wl-drawer-sidebar-bg: #fff;
    --gw-wl-drawer-sidebar-width: 320px;
    --gw-wl-drawer-sidebar-backdrop-bg: rgba(0, 0, 0, 0.4);
    --gw-wl-product-info-position: -20px;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/wishlist.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,2BAA2B;IAC3B,wBAAwB;IACxB,sCAAsC;IACtC,2BAA2B;IAC3B,8DAA8D;IAC9D,wDAAwD;IACxD,+BAA+B;IAC/B,mCAAmC;IACnC,sDAAsD;IACtD,oCAAoC;AACxC","sourcesContent":["/* WISHLIST (prefix: 'wl') */\n:root {\n    --gw-wl-main-color: #9a5cb3;\n    --gw-wl-icon-color: #fff;\n    --gw-wl-drawer-btn-border-radius: 22px;\n    --gw-wl-drawer-btn-bg: #fff;\n    --gw-wl-drawer-btn-box-shadow: rgb(218, 218, 218) 0px 3px 14px;\n    --gw-wl-drawer-btn-text-color: var(--gw-dark-text-color);\n    --gw-wl-drawer-sidebar-bg: #fff;\n    --gw-wl-drawer-sidebar-width: 320px;\n    --gw-wl-drawer-sidebar-backdrop-bg: rgba(0, 0, 0, 0.4);\n    --gw-wl-product-info-position: -20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
