import { STATUS_TYPE, VIRGIN } from "@/constants/statuses";
import { PaginationMetaType } from "@/types";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewEntity from "../entities/ReviewEntity";

export interface ReviewsStateBase extends EntityState<ReviewEntity, number> {
    paginationMeta: PaginationMetaType;
    canCreateReview: boolean;
    canCreateReviewMessage: string | null;
    status: STATUS_TYPE;
    createReviewStatus: STATUS_TYPE;
}

function createInitialState(): ReviewsStateBase {
    return {
        paginationMeta: {
            perPage: 0,
            totalCount: 0,
            currentOffset: 0,
        },
        canCreateReview: false,
        canCreateReviewMessage: null,
        status: VIRGIN,
        createReviewStatus: VIRGIN,
    };
}

@injectable()
@StoreConfig({ name: "reviewsStoreBase" })
class ReviewsStoreBase extends EntityStore<ReviewsStateBase> {
    constructor() {
        super(createInitialState());
    }

    setStatus(status: STATUS_TYPE) {
        this.update(() => ({
            status: status,
        }));
    }

    getStatus(): STATUS_TYPE {
        return this.getValue().status;
    }

    setCreateReviewStatus(status: STATUS_TYPE) {
        this.update(() => ({
            createReviewStatus: status,
        }));
    }
}

export default ReviewsStoreBase;
