import {
    array,
    boolean,
    nullable,
    number,
    object,
    string,
    union,
} from "superstruct";

import CustomerSchema from "./CustomerSchema";
import CustomFormQuestionSchema from "./CustomFormQuestionSchema";
import ReviewImagesSchema from "./ReviewImagesSchema";
import ReviewProductSchema from "./ReviewProductSchema";

export const ReviewCustomFormsSchema = object({
    answer: union([string(), number(), array(number())]),
    question: CustomFormQuestionSchema,
    questionId: number(),
    reviewId: number(),
});

const ReviewSchema = object({
    id: number(),
    body: string(),
    createdAt: string(),
    customer: CustomerSchema,
    images: array(ReviewImagesSchema),
    product: nullable(ReviewProductSchema),
    productId: nullable(number()),
    rating: number(),
    title: nullable(string()),
    customFormAnswers: array(ReviewCustomFormsSchema),
    isPublished: boolean(),
    vote: number(),
    shopName: string(),
    adminReply: nullable(string()),
    isAdminReplyPublished: boolean(),
    isCustomerVoted: boolean(),
});

export default ReviewSchema;
