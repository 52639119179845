// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --gw-tabs-tab-btn: #333;
    --gw-tabs-underline-color: #0b1e47;
    --gw-tabs-hover-color: #f8f8f8;
    --gw-tabs-no-selected-color: transparent;
    --gw-tabs-selected-color: #f2f2f2;
    --gw-tabs-border-radius: 0.4em;
    --gw-tabs-tag-bg: rgb(223, 225, 230);
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/tabs.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kCAAkC;IAClC,8BAA8B;IAC9B,wCAAwC;IACxC,iCAAiC;IACjC,8BAA8B;IAC9B,oCAAoC;AACxC","sourcesContent":[":root {\n    --gw-tabs-tab-btn: #333;\n    --gw-tabs-underline-color: #0b1e47;\n    --gw-tabs-hover-color: #f8f8f8;\n    --gw-tabs-no-selected-color: transparent;\n    --gw-tabs-selected-color: #f2f2f2;\n    --gw-tabs-border-radius: 0.4em;\n    --gw-tabs-tag-bg: rgb(223, 225, 230);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
