import { App } from "@/constants";
import { array, enums, string, type } from "superstruct";

const GrowaveFeaturesBodySchema = type({
    moneyFormat: string(),
    moneyWithCurrencyFormat: string(),
    shopCurrency: string(),
    availableApps: array(enums(Object.values(App))),
});

export default GrowaveFeaturesBodySchema;
