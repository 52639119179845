import { Infer, object, string } from "superstruct";

export const WishlistApplicationConfigSchema = object({
    drawerWidgetPlaceholderSelector: string(),
});

export const WishlistApplicationConfigToken = Symbol(
    "WishlistApplicationConfigToken"
);

export type WishlistApplicationConfigInterface = Infer<
    typeof WishlistApplicationConfigSchema
>;
