import OffsetPaginationSchema from "@/schemas/OffsetPagination.schema";
import ReviewSchema from "@apps/reviews/schemas/ReviewSchema";
import { array, assign, object } from "superstruct";

const ReviewsListResponseSchema = assign(
    OffsetPaginationSchema,
    object({
        items: array(ReviewSchema),
    })
);

export default ReviewsListResponseSchema;
