import { boolean, number, object, string } from "superstruct";

const CustomerSchema = object({
    firstName: string(),
    id: number(),
    lastName: string(),
    verifiedBuyer: boolean(),
});

export default CustomerSchema;
