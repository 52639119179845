import { Observable } from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { catchError, switchMap, tap } from "rxjs/operators";
import { singleton } from "tsyringe";
import urlJoin from "url-join";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import ApiClientInterface from "./ApiClientInterface";
import ApiClientRequest, {
    ApiClientHeaders,
    ApiClientQueryParams,
    ApiClientResponseType,
} from "./ApiClientRequest";
import { ApiClientResponse } from "./ApiClientResponse";
import { BAD_STATUS, DELETE, GET, PATCH, POST, PUT } from "./constants";
import {
    BadRequestException,
    ForbiddenException,
    GatewayTimeoutException,
    InternalServerErrorException,
    NotFoundException,
    ToManyRequestException,
    UnauthorizedException,
} from "./exceptions";
import UnknownFetchException from "./exceptions/UnknownFetchException";

const EXCEPTION_TYPE = {
    [BadRequestException.STATUS_CODE]: BadRequestException,
    [InternalServerErrorException.STATUS_CODE]: InternalServerErrorException,
    [ForbiddenException.STATUS_CODE]: ForbiddenException,
    [GatewayTimeoutException.STATUS_CODE]: GatewayTimeoutException,
    [NotFoundException.STATUS_CODE]: NotFoundException,
    [ToManyRequestException.STATUS_CODE]: ToManyRequestException,
    [UnauthorizedException.STATUS_CODE]: UnauthorizedException,
};

const responseToApiClientResponse = <T extends string>(
    response: Response,
    responseType: ApiClientResponseType
): Promise<ApiClientResponse<T>> => {
    return response.text().then((text) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const body = responseType === "json" ? JSON.parse(text) : text;
        return {
            body: body as T,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            redirected: response.redirected,
            url: response.url,
        };
    });
};

@singleton()
class ApiClient implements ApiClientInterface {
    constructor(
        private readonly baseURL: string = "/",
        private readonly headers: ApiClientHeaders = {},
        private readonly params: ApiClientQueryParams = {},
        private readonly logger?: LoggerInterface
    ) {}
    req(reqConfig: ApiClientRequest): Observable<ApiClientResponse<unknown>> {
        const responseType = reqConfig.responseType || "json";
        if (
            [POST, PATCH, PUT].includes(reqConfig.method) &&
            !reqConfig.contentType
        ) {
            this.logger?.warn(
                "You use POST/PATH method of ApiClient without passing contentType"
            );
        }
        if (reqConfig.contentType) {
            if (!reqConfig.headers) {
                reqConfig.headers = {};
            }
            reqConfig.headers["Content-Type"] = reqConfig.contentType;
        }
        const request$ = new Observable<Request>((subscriber) => {
            const qs = new URLSearchParams();
            const headers = new Headers();
            Object.entries({
                ...this.params,
                ...reqConfig.queryParams,
            }).forEach(([key, value]) => {
                qs.append(key, value.toString());
            });
            Object.entries({
                ...this.headers,
                ...reqConfig.headers,
            }).forEach(([key, value]) => {
                headers.append(key, value);
            });
            const baseURLWithCurrentOrigin = urlJoin(
                this.baseURL,
                reqConfig.url
            );
            const url = new URL(baseURLWithCurrentOrigin, location.origin);
            url.search = `?${qs.toString()}`;
            subscriber.next(
                new Request(url.toString(), {
                    method: reqConfig.method,
                    body: reqConfig.body,
                    headers: headers,
                    credentials: reqConfig.credentials,
                })
            );
            subscriber.complete();
        });
        return request$.pipe(
            switchMap((request) => {
                // TODO: Vladimir - посмотреть finalize
                return fromFetch(request);
            }),
            catchError((error: unknown) => {
                throw new UnknownFetchException(reqConfig, error);
            }),
            tap((responseOrError) => {
                if (responseOrError instanceof Error) {
                    throw responseOrError;
                }
                if (
                    responseOrError.status >= BAD_STATUS &&
                    EXCEPTION_TYPE[responseOrError.status]
                ) {
                    throw new EXCEPTION_TYPE[responseOrError.status](
                        reqConfig,
                        responseOrError
                    );
                }
            }),
            catchError((error: unknown) => {
                throw new UnknownFetchException(reqConfig, error);
            }),
            switchMap((response) =>
                responseToApiClientResponse(response, responseType)
            )
        );
    }
    get(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: GET,
        });
    }
    delete(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: DELETE,
        });
    }
    post(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: POST,
        });
    }
    put(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: PUT,
        });
    }
    patch(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.req({
            ...reqConfig,
            method: PATCH,
        });
    }
}

export default ApiClient;
