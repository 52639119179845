import { InjectionToken } from "tsyringe";

import ReviewsSettingsQuery from "./queries/ReviewsSettingsQuery";
import ReviewsQuery from "./widgets/reviews_widget/queries/ReviewsQuery";
import ReviewsService from "./widgets/reviews_widget/services/ReviewsService";

export const ReviewsApplicationConfigToken = Symbol(
    "ReviewsApplicationConfigToken"
);

export const ReviewsApplicationReviewsServiceToken = Symbol(
    "ReviewsApplicationReviewsServiceToken"
) as InjectionToken<ReviewsService>;

export const ReviewsApplicationReviewsQueryToken = Symbol(
    "ReviewsApplicationReviewsQueryToken"
) as InjectionToken<ReviewsQuery>;

export const ReviewsSettingsQueryToken = Symbol(
    "ReviewsSettingsQueryToken"
) as InjectionToken<ReviewsSettingsQuery>;

export const reviewsApplicationLoggerToken = Symbol(
    "ReviewsApplicationLoggerToken"
);
